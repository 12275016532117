<template>
    <div class="page">
        <van-nav-bar
                title="提现记录"
                left-text="返回"
                left-arrow
                @click-left="$router.back(-1)"
        />
        <div class="my-top">
            <div class="info-box" style="">
                <div class="head-portrait">
                    <div class="box">
                        <div class="treasure-font">
                            -余额-
                        </div>
                        <div class="treasure-num">18</div>
                    </div>
                    <van-button plain hairline round type="info" size="small" class="treasure-btn">提现记录
                    </van-button>
                </div>
            </div>
        </div>

        <div class="list-box">
            <div class="my-list-cell">
                <div class="historical-records">
                    历史记录
                </div>
                <div class="resume-cell">
                    <div>
                        <van-icon name="peer-pay"/>
                        <span>增值服务-加急奖励招工</span>
                    </div>
                    <div class="eidt-font">
                       <span>
                           2021.9.12
                       </span>
                        <span class="span-right">
                          <van-button plain hairline round type="info" size="small" class="span-btn">-20
                        </van-button>
                        </span>
                    </div>
                </div>
                <div class="resume-cell">
                    <div>
                        <van-icon name="peer-pay"/>
                        <span>增值服务-短信服务</span>
                    </div>
                    <div class="eidt-font">
                       <span>
                           2021.9.12
                       </span>
                        <span class="span-right">
                          <van-button plain hairline round type="info" size="small" class="span-btn">-20
                        </van-button>
                        </span>
                    </div>
                </div>
                <div class="resume-cell">
                    <div>
                        <van-icon name="peer-pay"/>
                        <span>平台赠送</span>
                    </div>
                    <div class="eidt-font">
                       <span>
                           2021.9.12
                       </span>
                        <span class="span-right">
                          <van-button plain hairline round type="info" size="small" class="span-btn">-20
                        </van-button>
                        </span>
                    </div>
                </div>
                <div class="resume-cell">
                    <div>
                        <van-icon name="peer-pay"/>
                        <span>活动赠送</span>
                    </div>
                    <div class="eidt-font">
                       <span>
                           2021.9.12
                       </span>
                        <span class="span-right">
                          <van-button plain hairline round type="info" size="small" class="span-btn">-20
                        </van-button>
                        </span>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                params: {
                    username: undefined,
                    status: undefined,
                    page: 0,
                    size: 40,
                },
            }
        },
        mounted() {

        },
        methods: {
            goMyTreasure() {
                this.$router.push(`my-treasure`)
            },

        },
        computed: {}
    }

</script>

<style lang="less" scoped>
    .my-top {
        height: 280px;
        width: 100%;
        background: linear-gradient(to right, #0E91FC, #08ADFD);

        .info-box {
            width: 100%;
            height: 100px;
            display: flex;
            justify-content: space-around;

            .head-portrait {
                margin-top: 15%;
                width: 120px;
                height: 120px;
                box-sizing: border-box;
                padding: 5px;
                border-radius: 50%;
                background-image: linear-gradient(#FFFFFF, #08ADFD);

                .box {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background: #FFFFFF;
                    text-align: center;

                    .treasure-font {
                        padding-top: 10px;
                    }

                    .treasure-num {
                        margin-top: 10px;
                        font-size: 20px;
                        font-weight: 600;
                    }
                }

                .treasure-btn {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -20px);
                    background: linear-gradient(to right, #F7D48D, #FDA65D);
                    color: #A04306;
                    border: none;
                    font-size: small;
                }
            }
        }
    }

    .list-box {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        height: calc(100vh - 200px);
        margin-top: -30px;
        background: #FFFFFF;

        .historical-records {
            margin-top: 20px;
            margin-left: 15px;
            font-size: 20px;
            color: #3C3C3C;
            font-weight: 600;
        }

        .my-list-cell {
            margin-top: 20px;
            position: absolute;
            width: 100%;

            .resume-cell {
                max-width: 700px;
                padding: 15px;
                margin-top: 10px;
                margin-left: 5px;
                border-bottom: 1px solid #F7F7F7;
                color: #212121;
                font-size: 16px;

                span {
                    margin-left: 10px;
                }

                .eidt-font {
                    color: #C3C3C3;
                    font-size: xx-small;
                    margin-left: 15px;
                    margin-top: 10px;

                    .span-right {
                        float: right;
                        margin-right: 10px;

                        .span-btn {
                            height: 20px;
                            width: 50px;
                            background: linear-gradient(to right, #F7D48D, #FDA65D);
                            color: #FFFFFF;
                            border: none;
                        }
                    }
                }
            }
        }
    }

</style>
